.page-header-alt {
    background-color: $white;
    padding: $layout-content-gutter;
    margin-top: -$layout-content-gutter;
    margin-left: -$layout-content-gutter;
    margin-right: -$layout-content-gutter;

    @include screen-mobile {
        margin-left: -$layout-content-gutter-sm;
        margin-right: -$layout-content-gutter-sm;
    }

    &.overlap {
        margin-bottom: -4.6875rem;
    }

    &.full-screen {
        position: relative;
        width: 100vw;
    }
}