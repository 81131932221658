.footer {
    height: $footer-height;
    display: flex;
    margin: 0;
    align-items: center;
    border-top: 1px solid $border-color;
    justify-content: space-between;
    color: white;
    padding-left: 25px;
    padding-right: 25px;

    @include screen-mobile {
        justify-content: center;
        flex-direction: column;
    }
}

.footer a {
  color: #fff;
}
