.ant-page-header {
    color: $gray-dark;
    font-size: $font-size-base;
}

.ant-page-header-title-view-title {
    color: $gray-dark;
    font-weight: $font-weight-semibold;
    line-height: $line-height-base;
}

.ant-page-header-title-view-sub-title {
    padding-right: 12px;
    color: $gray-light;
    font-size: $font-size-base;
}    
